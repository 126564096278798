import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import './assets/css/index.scss'
import './assets/icons/index.js'

import { registerStore } from '@/store'
import i18n from '@/i18n'

createApp(App).use(router)
  .use(createPinia())
  .use(i18n)
  // .provide('$store', Store)
  .mount('#app')

// 全局注册
registerStore()
