import * as store from './configure'
const appStore = {}
export const registerStore = () => {
  Reflect.ownKeys(store).forEach((keys) => {
    // 排除__esModule,进行全局注册，避免重复打包时重复实例化
    typeof store[keys] === 'function' && (() => {
      appStore[keys] = store[keys]()
    })()
  })
  // console.log('appStore', appStore)
}

export default appStore
