
import { defineStore } from 'pinia'
export const useTokenSetup = defineStore('token', () => {
  // 用户是否登录
  const token = ref(false)
  const getToken = () => token
  const setToken = (data) => {
    token.value = data
  }

  return {
    token,
    getToken,
    setToken
  }
})

export const useAsideSetup = defineStore('aside', () => {
  // 是否显示侧边栏
  const showAside = ref(false)
  const getShowAside = () => showAside
  const setShowAside = (data) => {
    showAside.value = data
  }

  return {
    showAside,
    getShowAside,
    setShowAside
  }
})

export const useSearchWordSetup = defineStore('searchWord', () => {
  // 搜索关键词
  const searchWord = ref('')
  const getSearchWord = searchWord
  const setSearchWord = (data) => {
    searchWord.value = data
  }
  return {
    searchWord,
    getSearchWord,
    setSearchWord
  }
})

export const useActiveNavNameSetup = defineStore('activeNavName', () => {
  const activeNavName = ref('')
  const getActiveNavName = activeNavName
  const setActiveNavName = (data) => {
    activeNavName.value = data
  }
  return {
    activeNavName,
    getActiveNavName,
    setActiveNavName
  }
})


// export default {
//   state: {
//     token: false, // 用户是否登录
//     showAside: false, // 是否显示侧边栏
//     searchWord: '', // 搜索关键词
//     activeNavName: '' // 导航栏名称
//   },
//   getters: {
//     token: (state) => state.token,
//     activeNavName: (state) => state.activeNavName,
//     showAside: (state) => state.showAside,
//     searchWord: (state) => state.searchWord
//   },
//   mutations: {
//     setToken: (state, token) => {
//       state.token = token
//     },
//     setActiveNavName: (state, activeNavName) => {
//       state.activeNavName = activeNavName
//     },
//     setShowAside: (state, showAside) => {
//       state.showAside = showAside
//     },
//     setSearchWord: (state, searchWord) => {
//       state.searchWord = searchWord
//     }
//   }
// }
