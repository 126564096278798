export default {
  nav: {
    Home: 'HOME',
    product: 'PRODUCT',
    news: 'ACCESSORIES',
    introduce: 'PROVIDE PRICING'
  },
  home: {
    Introduction: 'Company Profile',
    Introductions: 'The company was founded in 2018 in China (Shanghai). The company\'s business consists of five main segments: sale of mining equipment, purchase of mining equipment, maintenance of mining equipment, hosting of mining equipment, operation and maintenance of mining farms, etc. With the unique supply chain advantages, users can buy machines for less money, with the benefits of multi-channel access, users can sell more machines for more money, and with the benefits of maintaining, hosting, operating and maintaining mining farms, users can earn more money. Our company will offer you a one-stop solution for users on “How to buy with confidence”, “Where to sell”, “How easy it is to mine” and other questions. For four years, the company has been adhering to the principle of "Integrity in work" and "Real basis" and has received recognition from buyers in China and abroad. The company\'s business covers China, Russia, Kazakhstan, Kyrgyzstan, Singapore, Laos and continues to expand its activities in other countries.',
    advantage: 'company advantage',

    procurement: 'Source procurement',
    procurementC: 'The company has two kinds of machines: new machines and second-hand machines. New machines are directly ordered from Ant, Shenma, Avalon, and Innosilicon manufacturers. Second-hand machines are directly purchased from major mine owners to ensure that the quantity, quality and price advantage.',
    supplyQuality: 'High-quality supply',
    supplyQualityC: 'We provide the highest quality inspection, maintenance, cleaning, packaging, transportation, warehousing, overseas warehouses and other comprehensive services. All our products must undergo strict testing and various performance tests to provide customers with high-quality guarantees, Let customers buy with more confidence.',
    spotSales: 'Spot sales',
    spotSalesC: 'all machines sold by the company support self-pickup testing. The company has warehouses in Moscow, Kyrgyzstan, Kazakhstan, China, Singapore, and Laos to provide customers with worry-free procurement solutions.',
    highQualityService: 'High-quality service',
    highQualityServiceC: '6 years of mining experience to provide customers with full-chain mining consultation, making it easier for customers to get started with less pit mining, and providing customers with factory accessories procurement, so that customers’ machines can run more stably and worry-free.'
  },
  product: {
    title: 'Current Position：Home / Product',
    manufacturer: 'manufacturer',
    consumption: 'Energy Consumption',
    hash: 'HashRate',
    model: 'model',
    old: 'New and Used',
    get: 'GET A QUOTE',
    details: 'DETAILS',
    used: 'Used',
    new: 'new',
    refurbish: 'refurbish',
    successfully: 'Message successful'
  },
  popup: {
    leave: 'Leave a Message We will call you back soon!\n',
    productInfo: 'product information',
    placeholderA: 'Please enter the specific machine model you need, for example: Core Power T2T',
    placeholderB: 'Phone number',
    placeholderC: 'Email number',
    placeholderD: 'Required quantity',
    hintA: 'Please enter content',
    hintB: 'Please enter a phone number',
    hintC: 'Please enter the desired quantity',
    hintD: 'Please enter your email number'
  },
  accessories: {
    title: 'Current Position：Home / Accessories',
    tabA: 'Fan',
    tabB: 'Power supply',
    tabC: 'Control panel',
    tabD: 'Other Accessories'
  },
  details: {
    title: 'Current Position：Product / Product display  / ',
    parameter: 'Parameter content',
    Introduction: 'Introduction',
    describe: 'describe:'
  },
  pricing: {
    title: 'Current position: First page / get citation',
    requiredModel: 'Required model:',
    contactPhone: 'Contact phone:',
    mailForCommunication: 'Mail for communication:',
    quantity: 'Quantity:'
  }
}
