import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    },
    {
        path: '/404',
        component: () => import('@/views/error/404.vue')
    },
    {
        path: '/',
        name: 'container',
        component: () => import('@/views/Container.vue'),
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('@/views/Home.vue')
            },
            {
                path: '/product',
                name: 'product',
                component: () => import('@/views/Product.vue')
            },
            {
                path: '/accessories',
                name: 'accessories',
                component: () => import('@/views/Accessories.vue')
            },
            {
                path: '/pricing',
                name: 'pricing',
                component: () => import('@/views/Pricing.vue')
            },
            {
                path: '/details',
                name: 'details',
                component: () => import('@/views/Details.vue')
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
